//@ts-nocheck
import { startOfMonth, startOfQuarter, startOfWeek } from 'date-fns'
import { useContext, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'

import {
  useGetPageClicksMutation,
  useGetPagesLinksBySiteIDMutation,
  useGetSitesInfoByClientMutation
} from '../../api/userApi'
import { Container } from '../../assets/styles/global'
import { Alert } from '../../components/alert'
import { AnalyticsContext } from '../../hooks/useAnalytics'
import { selectUserBilling } from '../../store/userBillingSlice'
import { Button } from '../../ui/button'
import { Loader } from '../../ui/loader'
import { convertDate } from '../../utils/convertDate'
import Dropdown from './dropdown'
import {
  ActionsGroup,
  Body,
  ContainerGlobal,
  DateGroup,
  DateGroupFields,
  DateGroupInputs,
  EmptyBody,
  EmptyTitle,
  Header,
  HeaderFields,
  LoaderBody
} from './style'

export const Metrika = () => {
  const [getSitesInfoByClient, { data: sitesInfoByClient }] = useGetSitesInfoByClientMutation()
  const [getPagesLinksBySiteID, { data: pagesLinksBySiteID }] = useGetPagesLinksBySiteIDMutation()
  const [getPageClicks, { data: pageClicks, isSuccess: isPageClicksSuccess }] = useGetPageClicksMutation()

  const { billingSelectedDealerId } = useSelector(selectUserBilling)

  const [selectedValue, setSelectedValue] = useState('')
  const [selectedPage, setSelectedPage] = useState('')
  const [activeButton, setActiveButton] = useState('today')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const [iframeHeight, setIframeHeight] = useState('100vh')
  const [isSuccessFrameGenerate, setSuccessFrameGenerate] = useState(false)

  const [sites, setSites] = useState([])
  const [pages, setPages] = useState([])

  const [currentWidthContainer, setCurrentWidthContainer] = useState(0)
  const [currentPageClicks, setCurrentPageClicks] = useState([])

  const isDateRangeSelected = startDate && endDate

  const currentPageClicksRef = useRef([])
  const iframe = useRef(null)
  const isInitIframe = useRef(false)

  const [isIframeInteraction, setIframeInteraction] = useState(true)

  const isIframeInteractionRef = useRef(true)
  const isIframeInteractedByFields = useRef(false)
  const isDetailPageSelectedInDropdown = useRef(false)

  const [isEnabledClicks, setEnabledClicks] = useState(true)

  const { buttonClicked } = useContext(AnalyticsContext)

  const [isCrossOrigin, setCrossOrigin] = useState(false)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const element = document.querySelector('.container')
    const style = window.getComputedStyle(element)
    const width = element.offsetWidth
    const paddingLeft = parseFloat(style.paddingLeft)
    const paddingRight = parseFloat(style.paddingRight)
    const widthWithoutPadding = width - paddingLeft - paddingRight

    setCurrentWidthContainer(widthWithoutPadding)
  }, [])

  useEffect(() => {
    setCurrentPageClicks(pageClicks?.data)
    currentPageClicksRef.current = pageClicks?.data
  }, [pageClicks])

  useEffect(() => {
    if (selectedPage && selectedValue && !isSuccessFrameGenerate) {
      iframe.current = document.querySelector('iframe')
      const handleMessage = (event: MessageEvent) => {
        if (event?.data?.page_id) {
          const { page_id, page_generated, uri } = event?.data
          if (pages.every((page) => page.page_id !== page_id) && !isDetailPageSelectedInDropdown.current) {
            setSelectedPage({ page_id, uri })
            return
          }
          const currentPage =
            pages.find((page) => page.page_id === page_id) ??
            pages.find((page) => page.page_id === 'detail_page' && page_generated === 'true')
          if (currentPage) {
            setSelectedPage(currentPage)
          }
        }
      }

      window.addEventListener('message', handleMessage)

      return () => {
        window.removeEventListener('message', handleMessage)
      }
    }
  }, [selectedPage, selectedValue, isSuccessFrameGenerate])

  useEffect(() => {
    ;(async () => {
      if (selectedValue?.id && selectedPage?.page_id) {
        isIframeInteractionRef.current = isIframeInteraction
        await getPageClicks({
          clientId: billingSelectedDealerId,
          siteId: selectedValue?.id,
          pageId: selectedPage?.page_id,
          sw: currentWidthContainer,
          dateFrom: convertDate(startDate, '.'),
          dateTo: convertDate(endDate, '.')
        })
      }
    })()
  }, [isIframeInteraction, selectedValue])

  useEffect(() => {
    if (iframe.current && isPageClicksSuccess && !isInitIframe.current && isIframeInteraction) {
      iframe.current.addEventListener('load', () => {
        if (isIframeInteractionRef.current) {
          handleIframeLoad()
        }
      })
      isInitIframe.current = true
    }
  }, [isPageClicksSuccess, isIframeInteraction])

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealerId && selectedValue?.id && selectedPage?.page_id && isDateRangeSelected) {
        if (!isIframeInteractedByFields.current) {
          await getPageClicks({
            clientId: billingSelectedDealerId,
            siteId: selectedValue?.id,
            pageId: selectedPage?.page_id,
            sw: currentWidthContainer,
            dateFrom: convertDate(startDate, '.'),
            dateTo: convertDate(endDate, '.')
          })

          setIframeInteraction(true)
        }
        setSuccessFrameGenerate(false)
      }
    })()
  }, [selectedPage, startDate, endDate, isDateRangeSelected, isIframeInteractedByFields.current])

  useEffect(() => {
    handleIframeLoad()
  }, [currentPageClicks])

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealerId) {
        getSitesInfoByClient(billingSelectedDealerId)
      }
    })()
  }, [billingSelectedDealerId])

  useEffect(() => {
    setLoading(true)
    setCrossOrigin(false)
    ;(async () => {
      if (selectedValue?.host) {
        await getPagesLinksBySiteID({ clientId: billingSelectedDealerId, siteId: selectedValue?.id })
        setTimeout(() => {
          setLoading(false)
          if (selectedValue?.udp_metrica_enabled) {
            setCrossOrigin(!selectedValue?.iframe_allowed)
          } else {
            setCrossOrigin(true)
          }
        }, 3000)
      }
      setEnabledClicks(selectedValue?.udp_metrica_clickmap_enabled)
    })()
  }, [selectedValue])

  useEffect(() => {
    setTimeout(() => {
      calculateIframeHeight()
    })
  }, [buttonClicked])

  useEffect(() => {
    setLoading(false)
    if (sitesInfoByClient) {
      setSites(sitesInfoByClient?.data.filter((site) => site.status === 'published'))
    } else {
      setCrossOrigin(true)
      setSites([])
    }
  }, [sitesInfoByClient])

  useEffect(() => {
    if (pagesLinksBySiteID) {
      setPages(pagesLinksBySiteID?.data)
    }
  }, [pagesLinksBySiteID])

  const handleDropdownChange = (option: {}) => {
    setSelectedValue(option)
  }

  const handleDropdownPagesChange = (option: {}) => {
    isDetailPageSelectedInDropdown.current = option?.page_id === 'detail_page'
    setSelectedPage(option)
  }

  const handleIframeLoad = () => {
    iframe.current?.contentWindow?.postMessage(
      {
        clicks: currentPageClicksRef.current
      },
      '*'
    )
  }

  const getBodyOffsetTop = () => {
    const navigation = document.getElementById('navigation')
    const metrikaHeader = document.getElementById('metrika-header')
    const navigationRect = navigation?.getBoundingClientRect()
    const metrikaHeaderRect = metrikaHeader?.getBoundingClientRect()
    const offsetTop = navigationRect?.height + metrikaHeaderRect?.height + window?.scrollY
    return offsetTop || 0
  }

  const calculateIframeHeight = () => {
    const offsetTop = getBodyOffsetTop()
    setIframeHeight(`calc(100vh - ${offsetTop}px - 2px)`)
  }

  const setToday = (setStartDate, setEndDate) => {
    const today = new Date()
    setStartDate(today)
    setEndDate(today)
  }

  const setThisWeek = (setStartDate, setEndDate) => {
    const today = new Date()
    setStartDate(startOfWeek(today, { weekStartsOn: 1 }))
    setEndDate(today)
  }

  const setThisMonth = (setStartDate, setEndDate) => {
    const today = new Date()
    setStartDate(startOfMonth(today))
    setEndDate(today)
  }

  const setThisQuarter = (setStartDate, setEndDate) => {
    const today = new Date()
    setStartDate(startOfQuarter(today))
    setEndDate(today)
  }

  const handleButtonClick = (buttonName, setDateFunction) => {
    setActiveButton(buttonName)
    setDateFunction(setStartDate, setEndDate)
  }

  return (
    <ContainerGlobal>
      <Container
        className="container"
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          position: 'absolute',
          left: '0',
          zIndex: '3',
          bottom: '0',
          background: 'transparent'
        }}
      >
        {!isEnabledClicks && (
          <div className="metrika-alert">
            <Alert theme="warning">
              {{
                text: 'Сбор кликов на данном сайте отключен'
              }}
            </Alert>
          </div>
        )}
      </Container>
      <Header id="metrika-header" style={{ display: buttonClicked ? 'flex' : 'none' }}>
        <HeaderFields>
          <DateGroup>
            <Dropdown options={sites} onChange={handleDropdownChange} />
          </DateGroup>
          <DateGroup>
            <Dropdown
              isSearch={pages?.length > 1}
              currentValue={selectedPage}
              options={pages}
              isDetailPageSelectedInDropdown={isDetailPageSelectedInDropdown.current}
              onChange={handleDropdownPagesChange}
            />
          </DateGroup>
        </HeaderFields>
        <HeaderFields>
          <DateGroupFields>
            <Button
              className={activeButton === 'today' ? '' : 'inactive'}
              variant={activeButton === 'today' ? 'default' : 'secondary'}
              onClick={() => handleButtonClick('today', setToday)}
            >
              Сегодня
            </Button>
            <Button
              className={activeButton === 'week' ? '' : 'inactive'}
              variant={activeButton === 'week' ? 'default' : 'secondary'}
              onClick={() => handleButtonClick('week', setThisWeek)}
            >
              Неделя
            </Button>
            <Button
              className={activeButton === 'month' ? '' : 'inactive'}
              variant={activeButton === 'month' ? 'default' : 'secondary'}
              onClick={() => handleButtonClick('month', setThisMonth)}
            >
              Месяц
            </Button>
            <Button
              className={activeButton === 'quarter' ? '' : 'inactive'}
              variant={activeButton === 'quarter' ? 'default' : 'secondary'}
              onClick={() => handleButtonClick('quarter', setThisQuarter)}
            >
              Квартал
            </Button>
          </DateGroupFields>
          <ActionsGroup>
            <DateGroupInputs className="date-inputs">
              <DateGroup>
                <DatePicker
                  selected={startDate}
                  onChange={(dates) => {
                    const [start, end] = dates
                    setStartDate(start)
                    setEndDate(end)
                  }}
                  className="datePicker-metric"
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd.MM.yyyy"
                  showIcon
                />
              </DateGroup>
            </DateGroupInputs>
            <Button
              className={!isIframeInteraction ? 'active' : ''}
              onClick={() => {
                setIframeInteraction(!isIframeInteraction)
                isIframeInteractedByFields.current = !isIframeInteractedByFields.current
              }}
              variant={'secondary'}
            >
              Взаимодействие с сайтом
            </Button>
          </ActionsGroup>
        </HeaderFields>
      </Header>
      <Body className="body-frame">
        {isCrossOrigin && (
          <EmptyBody style={{ height: iframeHeight }}>
            <EmptyTitle>Содержимое сайта не может быть загружено!</EmptyTitle>
          </EmptyBody>
        )}
        {isLoading && (
          <LoaderBody>
            <Loader />
          </LoaderBody>
        )}
        <iframe
          frameborder="0"
          sandbox="allow-popups allow-scripts allow-forms allow-same-origin allow-top-navigation-by-user-activation"
          title="Ваш сайт"
          width={'100%'}
          src={`https://${selectedValue?.host}${selectedPage?.uri}?is_iframe=${isIframeInteraction}`}
          style={{
            width: '100%',
            border: 'none',
            height: iframeHeight,
            position: 'relative',
            opacity: !isLoading && selectedPage?.uri ? '1' : '0'
          }}
        />
      </Body>
    </ContainerGlobal>
  )
}
